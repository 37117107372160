import React from "react";
import { Box, Button, Fade } from "@mui/material";
import Link from "next/link";
import { keyframes } from "@emotion/react";
import { Theme } from "@mui/material/styles";
import { NavItem } from "@/types/navigation";

const fadeIn = keyframes`
 from {
   opacity: 0;
   transform: translateY(-10px);
 }
 to {
   opacity: 1;
   transform: translateY(0);
 }
`;

interface DesktopNavProps {
    navItems: NavItem[];
    customTheme: Theme;
    fadeDuration: number;
    staggerDelay: number;
}

const DesktopNav: React.FC<DesktopNavProps> = ({ navItems, customTheme, fadeDuration, staggerDelay }) => (
    <Box component="nav" sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        {navItems.map((item, index) => (
            <Box
                key={item.path}
                sx={{
                    animation: `${fadeIn} ${fadeDuration}ms ${index * staggerDelay}ms both`,
                }}
            >
                <Button
                    component={Link}
                    href={item.path}
                    sx={{
                        marginRight: customTheme.spacing(2),
                        color: customTheme.palette.text.primary,
                        backdropFilter: 'blur(10px)',  // Frosted glass effect
                        backgroundColor: customTheme.palette.background.paper + '35', // semi-transparent background
                        padding: customTheme.spacing(1, 3),
                        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            backgroundColor: customTheme.palette.background.paper, // Solid color on hover
                            boxShadow: `0 1px 3px ${customTheme.palette.action.hover}`,
                        },
                    }}
                >
                    {item.label}
                </Button>
            </Box>
        ))}
    </Box>
);

export default DesktopNav;


