'use client';
import React, { useState, useEffect } from "react";
import { Toolbar, Container, useMediaQuery, useTheme as useMuiTheme, Box } from "@mui/material";
import Link from "next/link";
import { useTheme } from "@/hooks/useTheme";
import { NAV_ITEMS } from "@/config/constants";
import AppBar from "./Appbar";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import ActionIcons from "./ActionIcons";
import Image from "next/image";
import LogoComponent from "../LogoComponent";
import { ForkRight } from "@mui/icons-material";

const FADE_DURATION = 300;
const STAGGER_DELAY = 50;

const Header: React.FC = () => {
    const { mode, toggleTheme, theme: customTheme } = useTheme();
    const muiTheme = useMuiTheme();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    if (!isMounted) return null;

    return (
        <AppBar position="fixed" elevation={0}>
            <Container maxWidth="lg">
                <Toolbar sx={{
                    padding: customTheme.spacing(0, 2),
                    flexGrow: 1,
                    justifyContent: 'space-between', // Ensure space between logo and other elements
                }}>
                    {/* Logo Placeholder on the Left */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginRight: customTheme.spacing(2),
                        }}
                    >
                        <Link href="/" passHref>
                            <LogoComponent />
                        </Link>
                    </Box>


                    {/* Desktop Navigation */}
                    {!isMobile && (
                        <DesktopNav
                            navItems={NAV_ITEMS}
                            customTheme={customTheme}
                            fadeDuration={FADE_DURATION}
                            staggerDelay={STAGGER_DELAY}
                        />
                    )}

                    {/* Action Icons */}
                    <ActionIcons
                        isMobile={isMobile}
                        mode={mode}
                        toggleTheme={toggleTheme}
                        toggleDrawer={toggleDrawer}
                        customTheme={customTheme}
                        fadeDuration={FADE_DURATION}
                        staggerDelay={STAGGER_DELAY}
                        navItemsLength={NAV_ITEMS.length}
                    />

                    {/* Mobile Navigation */}
                    {isMobile && (
                        <MobileNav
                            drawerOpen={drawerOpen}
                            toggleDrawer={toggleDrawer}
                            navItems={NAV_ITEMS}
                            mode={mode}
                            toggleTheme={toggleTheme}
                            customTheme={customTheme}
                            fadeDuration={FADE_DURATION}
                            staggerDelay={STAGGER_DELAY}
                        />
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
