export const designTokens = {
  colors: {
    primaryLight: '#212121',
    primaryDark: '#E0E0E0',
    secondaryLight: '#FF4081',
    secondaryDark: '#F48FB1',
    backgroundLight: '#F5F5F5',
    backgroundDark: '#121212',
    surfaceLight: '#FFFFFF',
    surfaceDark: '#1E1E1E',
    textPrimaryLight: '#212121',
    textSecondaryLight: '#757575',
    textPrimaryDark: '#E0E0E0',
    textSecondaryDark: '#BDBDBD',
    errorLight: '#F44336',
    errorDark: '#EF9A9A',
    warningLight: '#FFA000',
    warningDark: '#FFCC80',
    infoLight: '#2196F3',
    infoDark: '#90CAF9',
    successLight: '#4CAF50',
    successDark: '#A5D6A7',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  borderRadius: {
    default: '6px',
  },
  fonts: {
    base: '16px',
    fontFamily: '"Geist VF", sans-serif',
    fontFamilyMono: '"Geist Mono VF", monospace',
  },
  typography: {
    h1: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.35,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
  },
  breakpoints: {
    xs: '0px',
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
  },
};


