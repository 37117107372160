'use client';

import React from 'react';
import { Typography, Box } from '@mui/material';
import { COPYWRITE_TEXT } from '@/config/constants';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ py: 2, textAlign: 'center' }}>
      <Typography variant="body2">{COPYWRITE_TEXT}</Typography>
    </Box>
  );
};

export default Footer;


