import { AppBar as MuiAppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%, transparent 100%)`,
    backdropFilter: 'blur(1px)',
    transition: 'all 0.5s cubic-bezier(.04,.62,.84,.5)',
    // '&:hover': {
    //     backdropFilter: 'blur(3px)',
    //     background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%, ${theme.palette.background.default} 60%, transparent 100%)`,
    // },
    // '&::after': {
    //     content: '""',
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '100%',
    //     // background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%, ${theme.palette.background.default} 60%, transparent 100%)`,
    //     opacity: 0,
    //     pointerEvents: 'none',
    //     transition: 'opacity 0.5s cubic-bezier(.04,.62,.84,.5)',
    // },
    // '&:hover::after': {
    //     opacity: 1,
    // },
}));

export default AppBar;