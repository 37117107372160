export const SITE_TITLE = 'Sabás Mendívil - Software Engineer';
export const SITE_DESCRIPTION = `Explore Sabás Mendívil's website, a software engineer and AI advocate, passionate about innovative solutions, web development, and showcasing cutting-edge projects.`;
export const SITE_NAME = 'Sabás Mendívil';
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://sabasmendivil.com';

export const TWITTER_HANDLE = '@sabasmendivil';
export const COPYWRITE_TEXT = `© ${new Date().getFullYear()} Sabás Mendívil. All rights reserved.`;
export const HOMEPAGE_TITLE = 'Welcome to Sabás Mendívil\'s Personal Website';
export const HOMEPAGE_DESCRIPTION = 'Explore my work, projects, and thoughts on technology, web development, and AI advocacy.';
export const NAV_ITEMS = [
    { label: 'Home', path: '/' },
    { label: 'Projects', path: '/projects' },
    { label: 'About', path: '/about' },
    { label: 'Skills', path: '/skills' },
    { label: 'Contact', path: '/contact' },
];
export const REVALIDATE_TIME = 60 * 60 * 24;