import * as React from "react";
import { useTheme } from "@mui/material/styles";

const LogoComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100" // Ensures the content scales within the given viewbox
            height="56px" // Fixed height
            width="auto" // Let the width adjust proportionally to maintain aspect ratio
            {...props}
        >
            {/* Define filters for blur and shadow */}
            <defs>
                <filter id="acrylicBlur" x="-50%" y="-50%" width="200%" height="200%">
                    {/* The blur effect to create an acrylic look */}
                    <feGaussianBlur stdDeviation="6" />
                    <feComponentTransfer>
                        <feFuncA type="linear" slope="0.6" /> {/* Controls the transparency of the blur */}
                    </feComponentTransfer>
                </filter>

                <filter id="dropShadow" x="-50%" y="-50%" width="200%" height="200%">
                    <feDropShadow
                        dx="4" dy="4" stdDeviation="3"
                        floodColor={theme.palette.action.active} // Shadow color
                        floodOpacity="0.3"
                    />
                </filter>
            </defs>

            {/* The background square with acrylic blur and tint */}
            <rect
                width="100"
                height="100"
                fill={theme.palette.background.default} // Background color from the theme
                fillOpacity="0.5" // Tint the background a bit
                style={{ backdropFilter: 'blur(10px)' }} // CSS property for acrylic effect
                filter="url(#acrylicBlur)" // Apply the blur filter
            />

            {/* Centered Text with stroke and high-contrast drop shadow */}
            <text
                x="45%" // Moves the text slightly left
                y="60%" // Moves the text slightly downwards
                width={100}
                height={100}
                fill="transparent" // No fill, just stroke
                stroke={theme.palette.primary.main} // Primary color for the text stroke
                strokeWidth="2" // Thin stroke for the outline
                dominantBaseline="middle" // Align text vertically to the middle
                fontFamily="Arial, sans-serif" // Font styling
                fontSize={52} // Font size
                fontWeight="bold" // Bold font weight
                textAnchor="middle" // Align text horizontally to the middle
                opacity={0.8} // Slightly transparent text
                filter="url(#dropShadow)" // Apply the drop shadow filter
            >
                SM
            </text>
        </svg>
    );
};

export default LogoComponent;
